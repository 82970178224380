import { useTranslation } from 'react-i18next'
import { AuthenticationRequest } from '@api/endpoints/auth'
import { Box } from '@mui/material'
import { Button } from '@controls/Button'
import { Form, FormTextField, useFormTyped } from '@controls/Form'
import { Text } from '@controls/Text'
import { useAdminContext } from '../AdminContext'
import { LoginStyled } from './Login.styles'

const Login = (): React.ReactElement => {
  const { t } = useTranslation()
  const { login } = useAdminContext()

  const form = useFormTyped({
    resolver: AuthenticationRequest,
    onSubmit: login
  })

  return (
    <LoginStyled>
      <Form f={form}>
        <Box display="flex" flexDirection="column" rowGap={2}>
          <Text size="size-34" weight="regular" mb={4}>
            {t('pages.login.title')}
          </Text>
          <FormTextField name={form.names.username} label={t('pages.login.form.field.username')} />
          <FormTextField name={form.names.password} type="password" label={t('pages.login.form.field.password')} />
          <Button type="submit">{t('pages.login.buttons.login')}</Button>
        </Box>
      </Form>
    </LoginStyled>
  )
}

export default Login
