import styled, { css } from 'styled-components'

export const LoginStyled = styled('div')(
  ({ theme }) => css`
    padding-top: ${theme.spacing(10)};
    width: 100%;

    ${theme.mixins.flexCenterH}

    form {
      width: 100%;
      max-width: 420px;

      button {
        margin-top: ${theme.spacing(4)};
      }
    }
  `
)
